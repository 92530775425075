"use strict";
import { THEMA_COLOR_IN_TAILWIND } from "./const";
import { setTitle } from "./setTitle";
import { getTabNo } from "./getTabNo";

/**
 * tab要素のクリックイベントの関数
 *
 * @param {object} event
 */
export function execActivateTabDspSlct(event) {
  //タブとディスプレイをリンクさせる番号
  let no = getTabNo(event.currentTarget);
  //すべてのタブを未選択
  $(".myclass-selector-tab").addClass("opacity-40");
  //選択されたタブを強調
  $(event.currentTarget).removeClass("opacity-40");
  //すべてのテーブルを非表示
  $("td").addClass("hidden");
  //すべての描画済みバイクを非表示
  $(`[class*=myclass-selector-parts]`)
    .addClass("hidden opacity-60")
    .removeClass("opacity-80");

  //すべての選択済みimgを取得 クラスで判定
  let checkedImg = $(".myclass-selector-svg-selected");

  if (no != "4") {
    //選択されたタブのテーブル情報を表示
    $(`[class*=myclass-selector-td_${no}]`).removeClass("hidden");
    //選択されたバイクを表示
    $(`[class*=myclass-selector-parts_${no}]`).removeClass("hidden");

    //compareの場合で比較対象がある場合
  } else if (checkedImg.length > 1) {
    //描画済みのバイクをすべて表示
    $(`[class*=myclass-selector-parts]`).removeClass("hidden");
    //選択されたサイズ情報のみ表示
    $("td").addClass("hidden");
    $("td.bg-opacity-20").removeClass("hidden");
  }

  //geoの背景色初期化
  $("#wrapper-geometry").removeClass(
    "bg-gradient-to-br from-pink-50 to-pink-200 from-indigo-50 to-indigo-200 from-green-50 to-green-200 from-yellow-50 to-yellow-200"
  );

  //geoの背景色
  const color = {
    1: "pink",
    2: "indigo",
    3: "green",
    4: "yellow",
  };

  //geoの背景色設定
  $("#wrapper-geometry").addClass(
    `bg-gradient-to-br from-${color[no]}-50 to-${color[no]}-200`
  );

  //geo gridの設定
  $(".myclass-selector-grid").each(function () {
    let context = this.getContext("2d");
    context.strokeStyle = THEMA_COLOR_IN_TAILWIND.hex[no];
    context.stroke();
  });

  //タイトルの設定
  setTitle(no);
}
