"use strict";
/**
 * canvasを作成する関数
 *    実際に色を付けたり描画するのは別関数で行う
 * @param {object} myCanvas 予め設定しておいたcanvasの設定情報 class
 * @param {string} no タブとディスプレイを紐付ける番号
 * @returns 作成したcanvasをjQueryではない通常の要素として
 */
export function createCanvas(myCanvas, no) {
  //jQueryだとcssの設定がうまくいかない
  let cnvs = document.createElement("canvas");
  myCanvas.target.appendChild(cnvs);
  cnvs.width = myCanvas.width;
  cnvs.height = myCanvas.height;
  cnvs.style.position = "absolute";
  cnvs.style.left = myCanvas.left + "px";
  cnvs.style.top = myCanvas.top + "px";
  // cnvs.style.zIndex = zIndex;
  cnvs.style.transform = `rotate(${myCanvas.angle}deg)`;
  cnvs.style.transformOrigin = `${myCanvas.width / 2}px ${myCanvas.offset}px`;
  // cnvs.className = myCanvas.className;
  $(cnvs).attr("data-linkno", no);
  return cnvs;
}
