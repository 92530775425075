"use strict";

/**
 * 選択されたバイクの回数をPHPで登録
 * @param {object} prm {mkr: string , mdl: string}
 */
export function setDataToPHP(prm) {
  let url = "../php/countUp.php";
  // let url = "http://localhost/chari/php/countUp.php";
  const qPrm = new URLSearchParams(prm);
  fetch(url + "?" + qPrm, {
    mode: "cors",
  });
}
