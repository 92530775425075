"use strict";

import { THEMA_COLOR_IN_TAILWIND } from "./const";

/**
 * メーカー、モデル、サイズをタブとタイトルへセットする関数
 * @param {string} no リンク番号
 *
 */
export function setTitle(no) {
  let mkr = $("#select-maker_" + no).val();
  let mdl = $("#select-model_" + no).val();
  let size = $(`[id*=g-size2-td_${no}]` + ".bg-opacity-20").text(); //選択サイズは'bg-opacity-20'で判定

  //通常タブにslctMkr、slctMdlがある場合は"null"
  //比較タブでslctMkr、slctMdlがない場合は"undefined"
  if (mkr === "" || mkr === null || typeof mdl === "undefined") mkr = "";
  if (mdl === "" || mdl === null || typeof mdl === "undefined") mdl = "";
  if (size === "" || typeof size === "undefined") {
    size = "";
  } else {
    size = "size " + size;
  }

  //すべての選択済みsvgを取得
  let checkedImg = $(".myclass-selector-svg-selected");
  let txt;
  if (mkr === "") {
    if (no != 4) {
      txt = "Please select the maker, model and size first";
    } else if (checkedImg.length < 2) {
      txt = "Please select at least two bikes.";
    } else if (checkedImg.length > 1) {
      txt = "Select the size to emphasize the frame you want to see";
    }
  } else {
    txt = `${mkr}\r\n${mdl}\r\n${size}`;
  }

  //タイトル入力
  $("#geometry-title").text(txt);

  //タイトルの色をタブと合わせる
  for (const key in THEMA_COLOR_IN_TAILWIND["text"]) {
    $("#geometry-title").removeClass(THEMA_COLOR_IN_TAILWIND["text"][key]);
  }
  $("#geometry-title").addClass(THEMA_COLOR_IN_TAILWIND["text"][no]);

  // //補足情報取得 選択サイズは'bg-opacity-20'で判定
  // let calc = $(`[id*=gc-cal-td_${no}]` + ".bg-opacity-20").text();
  // let notice = $(`[id*=md-ntc-td_${no}]` + ".bg-opacity-20").text();

  // //補足情報追加 自動計算
  // $("#geometry-notice").text("");
  // if (calc != "") {
  //   let bike = new Geometry();
  //   let array = calc.split(",");
  //   $.each(array, function () {
  //     calc = calc.replace(this, bike[this]["name"]);
  //   });
  //   $("#geometry-notice").text(calc + " has been calculated.");
  // }

  // //補足情報追加
  // if (notice != "") {
  //   if ($("#geometry-notice").text() === "") {
  //     $("#geometry-notice").text(notice);
  //   } else {
  //     $("#geometry-notice").text($("#geometry-notice").text() + " " + notice);
  //   }
  // }
}
