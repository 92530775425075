"use strict";

/**
 * PHPより取得したメーカー、モデルの情報をセレクトへセットする関数
 * @param {string} setType 処理のタイプ
 * @param {string} no リンク番号
 * @param {json} value PHPより取得した情報
 */
export function setDataToMakerModel(setType, no, value) {
  //maker
  if (setType === "mkr") {
    let target = $("#select-maker_" + no).get(0);
    $("<option>", { text: "- Select maker -", disabled: true }).appendTo(
      target
    );
    for (const key in value) {
      $("<option>", { text: value[key]["mk_name"] }).appendTo(target);
    }
    target.options[0].selected = true;

    //model
  } else {
    let target = $("#select-model_" + no).get(0);
    $("<option>", { text: "- Select model -", disabled: true }).appendTo(
      target
    );
    for (const key in value) {
      $("<option>", {
        text: value[key]["md_name"] + " " + value[key]["md_year"],
      }).appendTo(target);
    }
    target.options[0].selected = true;
  }
}
