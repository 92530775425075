"use strict";
import { setTitle } from "../func/setTitle";
import { getDataFromPHP } from "../func/getDataFromPHP";
import { getTabNo } from "./getTabNo";
import { setDataToPHP } from "./setDataToPHP";

/**
 * セレクトの選択内容から次の選択に必要な情報を取得して表示する関数
 * @param {*} event セレクト（メーカー、モデル）のチェンジイベント
 */
export function execShowItems(event) {
  let no = getTabNo(event.currentTarget);

  //作成済みのサイズ情報を削除
  $(`[class*=myclass-selector-td_${no}]`).remove();

  //描画済みのバイクを削除
  $(`[class*=myclass-selector-parts_${no}]`).remove();

  let setType = event.target.id.split("_")[0];

  //メーカー選択の場合
  //セレクトのモデルを削除、選択されたメーカーのモデルをセットする
  if (setType === "select-maker") {
    //モデル名も初期化しておく
    $("#select-model_" + no)
      .find("option")
      .remove();
    setTitle(no);
    getDataFromPHP(no, {
      setType: "mdl",
      mkr: event.target.value,
    });

    //モデル選択の場合
  } else {
    setTitle(no);
    let mdl = $("#select-model_" + no).val();
    let year = mdl.split(" ");
    year = year[year.length - 1];
    mdl = mdl.replace(" " + year, "");
    getDataFromPHP(no, {
      setType: "geometry",
      mdl: mdl,
      year: year,
    });
    setDataToPHP({
      mkr: $("#select-maker" + no).val(),
      mdl: mdl,
      year: year,
    });
  }
}
