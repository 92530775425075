"use strict";

import { execSelectNavi } from "./execSelectNavi";

/**
 * ナビゲーションバーにイベントを設定
 * ＞webpackを利用しているのでhtmlに直接クリックイベントを設定できない
 *
 */
export function setEventToNavi() {
  $("#navi > li").each(function () {
    $(this).on("click", execSelectNavi);
  });
  $("#logo").on("click", execSelectNavi);
}
