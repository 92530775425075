"use strict";

/**
 * geoにグリッドを引く
 *    要素geoの高さと幅に合わせたグリッドを引く
 *    取要素自体が非表示だと高さも幅も0になる
 */
export function makePartsGridButton() {
  let geo = $("#geometry").get(0);

  // //makePartsGridButton
  // let partsGridButton = document.createElement("div");
  // geo.appendChild(partsGridButton);
  // partsGridButton.style.position = "absolute";
  // partsGridButton.style.right = "10px";
  // partsGridButton.style.top = "35px";
  // partsGridButton.className = "partsGridButton";
  // partsGridButton.textContent = "Parts Grid";
  //
  //   partsGridButton,
  //   "text-2xl m-2 p-2 shadow-lg rounded-lg w-44 h-12 bg-gray-300 opacity-50 text-center cursor-pointer transition duration-300 ease-in-out hover:bg-pink-500 hidden"
  // );
  // $(partsGridButton).on("click", function () {
  //   toggleClassInTailwind($(".partsGrid"), "hidden");
  // });
}
