"use strict";

//1px = 1mm
//大きすぎるので調整する
export const SCALE = 2.5;

export const THEMA_COLOR_IN_TAILWIND = {
  bg: {
    1: "bg-pink-500",
    2: "bg-indigo-500",
    3: "bg-green-500",
    4: "bg-yellow-500",
  },
  text: {
    1: "text-pink-500",
    2: "text-indigo-500",
    3: "text-green-500",
    4: "text-yellow-500",
  },
  hex: {
    1: "#ed64a6",
    2: "#6366f1",
    3: "#48bb78",
    4: "#ecc94b",
  },
};

export const GEO_SIZE = { width: "760", height: "520" };
//ratio 19:13

export const TIRE_COLOR = "rgba(150,150,150)";
export const FRAME_GRID_COLOR = "rgba(50,50,50)";
export const FRAME_GRID_LENGTH = 10;
export const FRAME_GRID_WIDTH = 3;
export const GRID_COLOR = "rgb(150,150,150)";
export const GRID_WEIGHT = 1;
export const HI_COLOR = "rgb(255,0,0)";
// export const  ZIDX_GEO = 0;

export const IMG_SVG = {
  unselected: `<div class="myclass-selector-svg-unselected grid place-items-center"><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 sm:w-7 sm:h-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
</svg></div>`,
  selected: `<div class="myclass-selector-svg-selected grid place-items-center"><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 sm:w-7 sm:h-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
</svg></div>`,
};
