"use strict";
import { execDrawParts } from "../draw/execDrawParts";
import { setGeoOriVal } from "../func/setGeoOriVal";
import { setGeoSclVal } from "../func/setGeoSclVal";
import { THEMA_COLOR_IN_TAILWIND } from "../func/const";

/**
 *サイズテーブルからジオメトリ情報を取得し描画する関数
 * @param {string} no リンク番号
 * @param {string} size サイズテーブルより選択された列
 */
export function execDrawBike(no, size) {
  //選択されたジオメトリテーブルから情報取得
  let bike = setGeoOriVal(no, size);
  setGeoSclVal(bike);

  //取得したバイク情報で描画
  execDrawParts("rearWheel", bike, no, size);
  execDrawParts("frontWheel", bike, no, size);
  execDrawParts("chainStay", bike, no, size);
  execDrawParts("seatStay", bike, no, size);
  execDrawParts("seatTube", bike, no, size);
  execDrawParts("topTube", bike, no, size);
  execDrawParts("headTube", bike, no, size);
  execDrawParts("bottomCup", bike, no, size);
  execDrawParts("fork", bike, no, size);
  execDrawParts("downTube", bike, no, size);
  execDrawParts("rearEndLength", bike, no, size);
  execDrawParts("bb", bike, no, size);
}
