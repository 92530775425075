"use strict";
import { execDrawBike } from "../draw/execDrawBike";
import { setTitle } from "../func/setTitle";
import { IMG_SVG, THEMA_COLOR_IN_TAILWIND } from "../func/const";
import { getTabNo } from "./getTabNo";

/**
 * サイズ選択によるフレームの描画
 * @param {object} event クリックイベント
 */
export function execSelectSize(event) {
  //タブとディスプレイをリンクさせる番号
  let no = getTabNo(event.currentTarget);
  let size = event.currentTarget.id.split("_")[2];

  //比較タブ判定
  //tab4のopacityが設定されていれば選択中ではない
  if ($(tab_4).hasClass("opacity-40")) {
    //全サイズのチェックマーク画像を未選択へ
    $(`[id*=dsc-slct-td_${no}]`).html(IMG_SVG.unselected);

    //選択されたサイズのチェックマークを選択済みへ
    $(`#dsc-slct-td_${no}_${size}`).html(IMG_SVG.selected);

    //選択済みのサイズ列の強調を解除
    $(`[class*=myclass-selector-td_${no}]`).removeClass(
      THEMA_COLOR_IN_TAILWIND["bg"][no] + " bg-opacity-20"
    );

    //選択されたサイズ列を強調 FRAME_COLOR_TAILWIND
    $(`.myclass-selector-td_${no}_${size}`).addClass(
      THEMA_COLOR_IN_TAILWIND["bg"][no] + " bg-opacity-20"
    );

    //描画済みのバイクを削除
    $(`[class*=myclass-selector-parts_${no}]`).remove();

    //バイクの描画
    execDrawBike(no, size);
  } else {
    //比較タブの場合は選択されたサイズのフレームを強調

    //設定済みのopacityを削除
    $(`[class*=myclass-selector-parts]`).removeClass(
      "opacity-60 opacity-80 z-10"
    );

    //選択されたバイクを強調
    $(`[class*=myclass-selector-parts_${no}]`).addClass("opacity-80 z-10");

    //比較対象のバイクを目立たなくする
    $(`[class*=myclass-selector-parts]`)
      .not(".opacity-80")
      .addClass("opacity-60");
  }

  //比較タブの標示
  //すべての選択済みimgを取得 クラスで判定
  let checkedImg = $(".myclass-selector-svg-selected");
  if (checkedImg.length > 1) {
    $("#tab_4")
      .removeClass("opacity-40 cursor-default")
      .addClass("cursor-pointer");
    //opacity-40  cursor-default
  } else {
    $("#tab_4")
      .removeClass("cursor-pointer")
      .addClass("opacity-40 cursor-default");
  }

  setTitle(no);
}
