"use strict";
/**
 * pageの切替
 * ロゴクリックで再読み込みさせない
 */
export function execSelectNavi() {
  let id;
  if (this.id === "logo") {
    id = "compare";
  } else {
    id = this.innerText.replace(" ", "");
  }
  $(".myclass-selector-page").addClass("hidden");
  $(`#${id}-page`).removeClass("hidden");
}
