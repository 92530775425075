"use strict";
import { execSelectSize } from "./execSelectSize";
import { IMG_SVG, THEMA_COLOR_IN_TAILWIND } from "./const";

/**
 * サイズ情報をテーブルにセットする関数
 * @param {object} target サイズ情報を入力するテーブルの全行（全項目）
 * @param {string} no タブとディスプレイを紐付ける番号
 * @param {json} value PHPより取得した情報
 */
export function setDataToSize(target, no, value) {
  //列 サイズの種類分
  for (let i = 0; i < value.length; i++) {
    //compare時にサイズが選択されていないと連想配列が空の時がある
    if (Object.keys(value[i]).length > 0) {
      //行 作成済みの項目数分
      target.each(function () {
        let idBase = this.id.split("-tr")[0];
        let key = idBase.replace("-", "_");
        let td = this.insertCell(-1); //テーブル行に新規セルを挿入し、セルへの参照を返す
        $(td)
          .addClass(`myclass-selector-td_${no}_${value[i]["g_size1"]}`)
          .addClass("w-12 sm:w-24")
          .on("click", execSelectSize);
        td.id = `${idBase}-td_${no}_${value[i]["g_size1"]}`;

        let data = value[i][key];

        //情報がなく算出した値 小数点を切り捨て
        if (Number.isFinite(data)) {
          td.textContent = Number(data.toFixed(1));

          //ジオメトリ情報の参照元URL アンカータグを設定
        } else if (idBase === "md-src") {
          let a = $("<a>", {
            href: data,
            target: "_blank",
            rel: "noopener noreferrer",
            text: "source",
          }).appendTo(td);
          a.addClass("hover:" + THEMA_COLOR_IN_TAILWIND.text[no]);

          //セレクトマーク セレクト状態の画像を設定
        } else if (idBase === "dsc-slct") {
          //innerHTMLでSVGを追加
          let div = $("<div>").appendTo(td);
          div.html(IMG_SVG.unselected);

          //タブ毎のフレームカラーをcompare時に取得できるように設定
          //phpからvalueを取得した場合、data === "undefined"
        } else if (idBase === "dsc-clr" || typeof data === "undefined") {
          td.textContent = no;

          //参照元より参照できた数値データ
        } else {
          td.textContent = data;
        }
        //カーソルポインタ データ列の最大幅 設定
        $(td).addClass("cursor-pointer text-center");
      });
    }
  }

  //自動計算した項目に色付け
  let calc = value[0]["gc_cal"];
  if (calc != "") {
    $.each(calc, function () {
      let name = this.replace("_", "-");
      $(`[id*=${name}-td_${no}]`).css("color", "#2986cc");
    });
  }

  let notice = value[0]["md_ntc"];
  if (notice != null) {
    let array = notice.split(",");
    $.each(array, function () {
      let name = this.replace("_", "-");
      $(`[id*=${name}-td_${no}]`).css("color", "#ff7777");
    });
  }
}
