"use strict";

/**
 * canvasへ線で描画する関数
 * @param {object} myLine 予め設定しておいた情報 クラスで生成
 */
export function drawLine(myLine) {
  let context = myLine.target.getContext("2d");
  context.strokeStyle = myLine.color;
  context.lineWidth = myLine.weight;
  context.moveTo(myLine.width / 2, 0);
  context.lineTo(myLine.width / 2, myLine.height);
  context.stroke();
}
