"use strict";
import {
  MyCanvas,
  MyDrawArc,
  MyDrawRect,
  MyDrawLine,
} from "../class/classDraw";
import {
  FRAME_GRID_LENGTH,
  FRAME_GRID_WIDTH,
  FRAME_GRID_COLOR,
  SCALE,
  TIRE_COLOR,
  THEMA_COLOR_IN_TAILWIND,
  GEO_SIZE,
} from "../func/const";
import { createCanvas } from "../draw/createCanvas";
import { drawArc } from "../draw/drawArc";
import { drawRect } from "../draw/drawRect";
import { drawLine } from "../draw/drawLine";

/**
 * geoへパーツを描画する関数
 * @param {string} parts パーツ名
 * @param {object} bike クラスGeometryで生成されているジオメトリ情報
 * @param {string} no リンク番号
 * @param {string} size サイズテーブルより選択された列
 */
export function execDrawParts(parts, bike, no, size) {
  //フレームカラー
  let clr = THEMA_COLOR_IN_TAILWIND["hex"][no];

  //通常の要素として取得
  let geo = $("#geometry").get(0);

  let myCanvas = new MyCanvas();
  myCanvas.target = geo;
  myCanvas.className = parts + " parts";
  myCanvas.offset = 0;

  let myDrawArc = new MyDrawArc();
  let myDrawRect = new MyDrawRect();
  myDrawRect.fillColor = clr;
  myDrawRect.tapered = 1;
  myDrawRect.tprType = "nml";

  let myDrawLine = new MyDrawLine();
  myDrawLine.width = FRAME_GRID_WIDTH;
  myDrawLine.weight = 1;
  myDrawLine.color = FRAME_GRID_COLOR;

  switch (parts) {
    case "rearWheel": // ほぼ同じ設定なのでor条件させている
    case "frontWheel":
      myCanvas.height = bike.gc_ts.sVal;
      myCanvas.width = bike.gc_ts.sVal;
      myCanvas.top = GEO_SIZE.height - bike.gc_ts.sVal;
      if (parts === "frontWheel") {
        myCanvas.left =
          bike.g_rwEtoC.sVal +
          bike.g_rch.sVal +
          bike.g_htHrz.sVal +
          bike.g_bcHrz.sVal +
          bike.g_fHrz.sVal -
          myCanvas.width / 2;
        myCanvas.left += bike.gc_bbstOfsHrz.sVal;
      } else {
        myCanvas.left = 0;
      }
      myDrawArc.weight = 25 / SCALE; //tire width temp
      myDrawArc.color = TIRE_COLOR;
      break;

    case "bb":
      myCanvas.height = bike.gc_bb.sVal;
      myCanvas.width = bike.gc_bb.sVal;
      myCanvas.top = GEO_SIZE.height - (bike.g_bbHei.sVal + myCanvas.width / 2);
      myCanvas.left = bike.g_rwEtoC.sVal - myCanvas.width / 2;
      myDrawArc.weight = 2;
      myDrawArc.color = clr;
      break;

    case "chainStay": //starting point:bb
      myCanvas.height = bike.g_csLen.sVal;
      myCanvas.width = bike.gc_csDia.sVal;
      myCanvas.top = GEO_SIZE.height - bike.g_bbHei.sVal;
      myCanvas.left = bike.g_rwEtoC.sVal - myCanvas.width / 2;
      myCanvas.angle = bike.g_csAgl.val + 90;
      myDrawRect.tapered = bike.gc_csTpr.val;
      break;

    case "seatTube": //starting point:bb
      myCanvas.height = bike.g_stCtoT.sVal;
      myCanvas.width = bike.gc_stDia.sVal;
      myCanvas.top = GEO_SIZE.height - bike.g_bbHei.sVal;
      if (bike.gc_stDia.sVal < bike.gc_bb.sVal) {
        myCanvas.left = bike.g_rwEtoC.sVal - myCanvas.width / 2;
      } else {
        myCanvas.left =
          bike.g_rwEtoC.sVal -
          myCanvas.width / 2 -
          (bike.gc_stDia.sVal - bike.gc_bb.sVal) / 2;
      }
      myCanvas.left += bike.gc_bbstOfsHrz.sVal;
      myCanvas.angle = bike.g_stAgl.val + 90;
      myDrawRect.tapered = bike.gc_stTpr.val;
      break;

    case "downTube": //starting point:bb
      myCanvas.height = bike.g_dtLen.sVal;
      myCanvas.width = bike.gc_dtDia.sVal;
      myCanvas.top =
        GEO_SIZE.height -
        bike.g_bbHei.sVal -
        (bike.gc_dtDia.sVal - bike.gc_bb.sVal) / 2; //dtBtm
      myCanvas.left = bike.g_rwEtoC.sVal - myCanvas.width / 2;
      myCanvas.left += bike.gc_bbstOfsHrz.sVal;
      myCanvas.angle = bike.g_dtAgl.val - 90;
      myDrawRect.tapered = bike.gc_dtTpr.val;
      break;

    case "seatStay": //starting point:rear tire
      myCanvas.height = bike.g_ssLen.sVal;
      myCanvas.width = bike.gc_ssDia.sVal;
      myCanvas.top = GEO_SIZE.height - bike.gc_ts.sVal / 2;
      myCanvas.left =
        bike.gc_ts.sVal / 2 + bike.gc_rwOfs.sVal - myCanvas.width / 2;
      myCanvas.angle = bike.g_ssAgl.val - 90;
      myDrawRect.tapered = bike.gc_ssTpr.val;
      break;

    case "topTube": //starting point:seat tube top
      myCanvas.height = bike.g_ttLen.sVal;
      myCanvas.width = bike.gc_ttDia.sVal;
      myCanvas.top =
        GEO_SIZE.height - (bike.g_stCtoCVrt.sVal + bike.g_bbHei.sVal);
      myCanvas.left =
        bike.g_rwEtoC.sVal - bike.g_stCtoCHrz.sVal - myCanvas.width / 2;
      myCanvas.left += bike.gc_bbstOfsHrz.sVal;
      myCanvas.angle = bike.g_ttAgl.val - 90;
      myDrawRect.tapered = bike.gc_ttTpr.val;
      myDrawRect.tprType = "rvs";
      break;

    case "headTube": //starting point:top tube
      myCanvas.height = bike.g_htLen.sVal;
      myCanvas.width = bike.gc_htDia.sVal;
      myCanvas.top = GEO_SIZE.height - (bike.g_stk.sVal + bike.g_bbHei.sVal);
      myCanvas.left = bike.g_rwEtoC.sVal + bike.g_rch.sVal - myCanvas.width / 2;
      myCanvas.left += bike.gc_bbstOfsHrz.sVal;
      myCanvas.angle = bike.g_htAgl.val - 90;
      myDrawRect.tapered = bike.gc_htTpr.val;
      myDrawRect.tprType = "rvs";
      break;

    case "bottomCup": //starting point:head tube bottom
      myCanvas.height = bike.gc_bcHei.sVal;
      myCanvas.width = bike.gc_htDia.sVal * 1.2;
      myCanvas.top =
        GEO_SIZE.height -
        (bike.g_stk.sVal + bike.g_bbHei.sVal - bike.g_htVrt.sVal);
      myCanvas.left =
        bike.g_rwEtoC.sVal +
        bike.g_rch.sVal +
        bike.g_htHrz.sVal -
        myCanvas.width / 2;
      myCanvas.left += bike.gc_bbstOfsHrz.sVal;
      myCanvas.angle = bike.g_htAgl.val - 90;
      break;

    case "fork": //starting point:bottom cup
      myCanvas.height = bike.g_fLen.sVal;
      myCanvas.width = bike.gc_fDia.sVal;
      myCanvas.top =
        GEO_SIZE.height -
        (bike.g_stk.sVal + bike.g_bbHei.sVal - bike.g_htVrt.sVal) +
        bike.g_bcVrt.sVal;
      myCanvas.left =
        bike.g_rwEtoC.sVal +
        bike.g_rch.sVal +
        bike.g_htHrz.sVal +
        bike.g_bcHrz.sVal -
        myCanvas.width / 2;
      myCanvas.left += bike.gc_bbstOfsHrz.sVal;
      myCanvas.angle = bike.g_htAgl.val - bike.g_fAgl.val - 90;
      myDrawRect.tapered = bike.gc_fTpr.val;
      break;

    case "rearEndLength": //starting point:rear tire
      myCanvas.height = bike.gc_re.sVal;
      myCanvas.width = bike.gc_csDia.sVal;
      myCanvas.top = GEO_SIZE.height - bike.gc_ts.sVal / 2;
      myCanvas.left =
        bike.gc_ts.sVal / 2 + bike.gc_rwOfs.sVal - myCanvas.width / 3;
      myCanvas.angle = 90;
      myDrawRect.tapered = 0.8;
      break;
  }

  //1マス分だけオフセット
  myCanvas.left += geo.clientLeft + 100 / SCALE;
  myCanvas.top += geo.clientTop - 100 / SCALE;

  //円形の描画
  if (parts === "rearWheel" || parts === "frontWheel" || parts === "bb") {
    myDrawArc.target = createCanvas(myCanvas, no);
    myDrawArc.target.id = `${parts}_${no}_${size}`;
    $(myDrawArc.target).addClass(
      "opacity-60 " + `myclass-selector-parts_${no}_${size}`
    );
    myDrawArc.height = myCanvas.height;
    myDrawArc.width = myCanvas.width;
    drawArc(myDrawArc);
  } else {
    //四角形の描画
    myDrawRect.target = createCanvas(myCanvas, no);
    myDrawRect.target.id = `${parts}_${no}_${size}`;
    $(myDrawRect.target).addClass(
      "opacity-60 " + `myclass-selector-parts_${no}_${size}`
    );
    myDrawRect.height = myCanvas.height;
    myDrawRect.width = myCanvas.width;
    myDrawRect.setTapered();
    drawRect(myDrawRect);
  }

  // //線の描画（パーツのグリッド）
  // //myCanvasの再設定 作成済みのmyCanvasの情報を使う
  // myCanvas.className = parts + " partsGrid";
  // myCanvas.left += myCanvas.width / 2 - FRAME_GRID_WIDTH / 2;
  // myCanvas.height += FRAME_GRID_LENGTH * 2;
  // myCanvas.width = FRAME_GRID_WIDTH;
  // myCanvas.top -= FRAME_GRID_LENGTH;
  // myCanvas.offset = FRAME_GRID_LENGTH;
  // myDrawLine.target = createCanvas(myCanvas, no);
  // myDrawLine.target, "opacity-60");
  // myDrawLine.height = myCanvas.height + FRAME_GRID_LENGTH * 2;
  // drawLine(myDrawLine);
}
