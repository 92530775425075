"use strict";
import { execActivateTabDspSlct } from "./modules/func/execActivateTabDspSlct";
import { makeGrid } from "./modules/draw/makeGrid";
import { makeTab } from "./modules/func/makeTab";
import { getDataFromPHP } from "./modules/func/getDataFromPHP";
import { makeTable } from "./modules/func/makeTable";
import { makePartsGridButton } from "./modules/func/makePartsGridButton";
import { setEventToNavi } from "./modules/func/setEventToNavi";
import { getTabNo } from "./modules/func/getTabNo";
// import "../dist/css/dist.css";

/**
 * 開始
 */
(function begin() {
  //タブ初期設定
  $(".myclass-selector-tab").on("click", execActivateTabDspSlct);

  //ジオメトリディスプレイの作成
  makeGrid();

  //タブの作成
  $(".myclass-selector-tab")
    .not("#tab_4")
    .each(function () {
      let no = getTabNo(this);
      makeTab(this);
      getDataFromPHP(no, { setType: "mkr" });
    });

  //ジオメトリテーブル作成
  makeTable();

  //ナビゲーションバーにイベントを設定
  setEventToNavi();
})();
