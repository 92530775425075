"use strict";
import { execShowItems } from "./execShowItems";
import { getTabNo } from "./getTabNo";

/**
 * セレクトエリア（メーカー、モデル、サイズ）を作成する関数
 * @param {object} target 対象のselect-maker_model
 */
export function makeTab(target) {
  let no = getTabNo(target);

  //メーカーとモデル選択用のセレクト作成
  $("<select>", { addClass: "slctMkr" })
    .on("change", execShowItems)
    .attr("id", "select-maker_" + no)
    .appendTo(target)
    .addClass(
      "text-base shadow-lg rounded-md cursor-pointer bg-white bg-opacity-60 h-6 hover:bg-opacity-70 sm:text-2xl sm:h-8"
    );
  $("<select>", { addClass: "slctMdl" })
    .on("change", execShowItems)
    .attr("id", "select-model_" + no)
    .appendTo(target)
    .addClass(
      "text-base shadow-lg rounded-md cursor-pointer bg-white bg-opacity-60 h-6 hover:bg-opacity-70 sm:text-2xl sm:h-8"
    );
}
