"use strict";
import { setDataToMakerModel } from "./setDataToMakerModel";
import { setDataToSize } from "../func/setDataToSize";

/**
 * PHPでメーカー、モデル、ジオメトリの情報を取得
 * JQUERYで簡素化したい
 * @param {string} no リンク番号
 * @param {object} prm {setType: string , mkr: string}
 */

export async function getDataFromPHP(no, prm) {
  let url = "../php/getGeo.php";
  // let url = "http://localhost:5500/php/getGeo.php";
  console.log(location.href);
  console.log(location.pathname);
  const qPrm = new URLSearchParams(prm);
  const res = await fetch(url + "?" + qPrm);
  const values = await res.json();
  if (prm["setType"] != "geometry") {
    setDataToMakerModel(prm["setType"], no, values);
  } else {
    setDataToSize($("#size-table > tr"), no, values);
  }
}
