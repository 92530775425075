"use strict";
import { Geometry } from "../class/classGeometry";

/**
 * 選択されたサイズ情報をテーブルから取得しリターンする関数
 * @param {string} no リンク番号
 * @param {string} size サイズテーブルより選択された列
 * @returns 取得したジオメトリ情報
 */
export function setGeoOriVal(no, size) {
  let bike = new Geometry();
  $(`.myclass-selector-td_${no}_${size}`).each(function () {
    let idBase = this.id.split("-td")[0];
    let key = idBase.replace("-", "_");
    bike[key]["val"] = this.textContent;
  });
  return bike;
}
