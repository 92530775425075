"use strict";
import { Geometry } from "../class/classGeometry";

/**
 * セレクトエリア（メーカー、モデル、サイズ）を作成する関数
 *
 */
export function makeTable() {
  //自転車パーツ分の項目を作成
  //項目はクラスGeometryより各パーツ名を取得
  let bike = new Geometry();
  let i = 0;
  for (const key in bike) {
    let tr = $("<tr>").appendTo($("#size-table"));
    tr.get(0).id = key.replace("_", "-") + "-tr";

    if (bike[key]["desc"] === "disp") {
      //tailwindのoddがうまくいかないので暫定対応
      if (i % 2 === 0) {
        tr.addClass("bg-gray-500 bg-opacity-10");
      }
      i++;
    } else {
      tr.addClass("hidden");
    }

    $("<th>", { text: bike[key]["name"] })
      .addClass("w-20 sm:w-40")
      .addClass("break-words text-left")
      .appendTo(tr);

    //break-words
  }
}
