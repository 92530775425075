"use strict";
/**
 * canvasへ円形で描画する関数
 * @param {object} myDrawArc 予め設定しておいた情報 クラスで生成
 */
export function drawArc(myDrawArc) {
  let context = myDrawArc.target.getContext("2d");
  context.strokeStyle = myDrawArc.color;
  context.fillStyle = myDrawArc.fillColor;
  context.lineWidth = myDrawArc.weight;
  context.arc(
    myDrawArc.width / 2,
    myDrawArc.height / 2,
    myDrawArc.height / 2 - myDrawArc.weight / 2,
    0,
    360,
    false
  );
  if (myDrawArc.fColor != null) {
    context.fill();
  }
  context.stroke();
}
