"use strict";
import { SCALE } from "../func/const";

/**
 * ジオメトリをスケールに合わせた値へ変換、小数点切り捨てする関数
 * @param {*} bike
 */
export function setGeoSclVal(bike) {
  for (const key in bike) {
    if (bike[key]["attr"] === "num") {
      bike[key]["val"] = Number(bike[key]["val"]);
      bike[key]["sVal"] = bike[key]["val"] / SCALE;
      bike[key]["val"] = Number(bike[key]["val"].toFixed(1));
      bike[key]["sVal"] = Number(bike[key]["sVal"].toFixed(1));
    }
  }
}
