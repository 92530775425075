"use strict";
import { GRID_COLOR, GRID_WEIGHT, SCALE, GEO_SIZE } from "../func/const";
import { createCanvas } from "../draw/createCanvas";
import { MyCanvas, MyDrawLine } from "../class/classDraw";
import { drawLine } from "../draw/drawLine";

/**
 * geoにグリッドを引く
 *    要素geoの高さと幅に合わせたグリッドを引く
 *    取要素自体が非表示だと高さも幅も0になる
 */
export function makeGrid() {
  //通常の要素として取得
  let geo = $("#geometry").get(0);
  let gridSize = 100 / SCALE; //グリッド1マス10cm(100mm)を何ピクセルで表示させるか SCALEが1なら実寸
  let lenY = Math.floor(GEO_SIZE.height / gridSize);
  let lenX = Math.floor(GEO_SIZE.width / gridSize);

  let myCanvas = new MyCanvas();
  myCanvas.target = geo;
  myCanvas.width = GRID_WEIGHT;
  myCanvas.height = GEO_SIZE.width;
  // myCanvas.left = geo.clientLeft;
  myCanvas.angle = -90;
  myCanvas.offset = 0;

  let myLine = new MyDrawLine();
  myLine.width = GRID_WEIGHT;
  myLine.height = GEO_SIZE.width;
  myLine.weight = GRID_WEIGHT;
  myLine.color = GRID_COLOR;

  //グリッド水平
  for (let i = 1; i < lenY; i++) {
    myCanvas.top = GEO_SIZE.height - i * gridSize; // + geo.clientTop; //geo.clientTopは境界線の幅
    myLine.target = createCanvas(myCanvas);
    $(myLine.target).addClass("myclass-selector-grid opacity-30");
    drawLine(myLine);
  }

  //グリッド垂直
  myCanvas.height = GEO_SIZE.height;
  myCanvas.top = 0; //geo.clientTop;
  myCanvas.angle = 0;
  myLine.height = GEO_SIZE.height;
  for (let i = 1; i < lenX; i++) {
    myCanvas.left = i * gridSize; //+ geo.clientLeft; //geo.clientLeftは境界線の幅
    myLine.target = createCanvas(myCanvas);
    $(myLine.target).addClass("myclass-selector-grid opacity-30");
    drawLine(myLine);
  }
}
