"use strict";

export class Geometry {
  dsc_slct = {
    name: "Select Size",
    val: "",
    sVal: "none",
    attr: "num",
    desc: "disp",
  };
  g_size1 = {
    name: "size1",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_size2 = {
    name: "Size",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  gc_stDia = {
    name: "SeatTube Diameter",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_stCtoT = {
    name: "SeatTube Center To Top",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  g_stCtoC = {
    name: "SeatTube Center To Center",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  g_stAgl = {
    name: "SeatTube Angle",
    val: "",
    sVal: "none",
    attr: "num",
    desc: "disp",
  };
  g_stCtoCVrt = {
    name: "SeatTube Center To Center Vertically",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_stCtoCHrz = {
    name: "SeatTube Center To Center Horizontally",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  gc_stTpr = {
    name: "SeatTube Tapered",
    val: "",
    sVal: "none",
    attr: "num",
    desc: "none",
  };
  gc_ttDia = {
    name: "TopTube Diameter",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_ttEfc = {
    name: "Effective TopTube Length",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  g_ttLen = {
    name: "TopTube Length",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  g_ttAgl = {
    name: "TopTube Angle",
    val: "",
    sVal: "none",
    attr: "num",
    desc: "none",
  };
  gc_ttTpr = {
    name: "TopTube Tapered",
    val: "",
    sVal: "none",
    attr: "num",
    desc: "none",
  };
  gc_htDia = {
    name: "HeadTube Diameter",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_htLen = {
    name: "HeadTube Length",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  g_htVrt = {
    name: "HeadTube Vertically",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_htHrz = {
    name: "HeadTube Horizontally",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_htAgl = {
    name: "HeadTube Angle",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  gc_htttCtoCVrt = {
    name: "HeadTube And TopTube Center To Center Vertically",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  gc_htdtCtoCVrt = {
    name: "HeadTube And DownTube Center To Center Vertically",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  gc_htTpr = {
    name: "HeadTube Tapered",
    val: "",
    sVal: "none",
    attr: "num",
    desc: "none",
  };
  gc_bcHei = {
    name: "BottomCup",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_bcVrt = {
    name: "BottomCup Vertically",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_bcHrz = {
    name: "BottomCup Horizontally",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  gc_csDia = {
    name: "ChainStay Diameter",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_csLen = {
    name: "ChainStay Length",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  g_csHrz = {
    name: "ChainStay Horizontally",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_csAgl = {
    name: "ChainStay Angle",
    val: "",
    sVal: "none",
    attr: "num",
    desc: "none",
  };
  gc_csTpr = {
    name: "ChainStay Tapered",
    val: "",
    sVal: "none",
    attr: "num",
    desc: "none",
  };
  gc_dtDia = {
    name: "DownTube Diameter",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_dtLen = {
    name: "DownTube Length",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_dtAgl = {
    name: "DownTube Angle",
    val: "",
    sVal: "none",
    attr: "num",
    desc: "none",
  };
  gc_dtTpr = {
    name: "DownTube Tapered",
    val: "",
    sVal: "none",
    attr: "num",
    desc: "none",
  };
  gc_ssDia = {
    name: "SeatStay Diameter",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_ssLen = {
    name: "SeatStay Length",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_ssAgl = {
    name: "SeatStay Angle",
    val: "",
    sVal: "none",
    attr: "num",
    desc: "none",
  };
  gc_ssOfsVrt = {
    name: "SeatStay Offset Vertically",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  gc_bbstOfsHrz = {
    name: "BottomBracket And SeatStay Offset Horizontally",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  gc_ssTpr = {
    name: "SeatStay Tapered",
    val: "",
    sVal: "none",
    attr: "num",
    desc: "none",
  };
  g_fc = {
    name: "FrontCenter",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  g_wb = {
    name: "WheelBase",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  g_bbDro = {
    name: "BottomBracket Drop",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  g_bbHei = {
    name: "BottomBracket Height",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  gc_bb = {
    name: "BottomBracket",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  gc_fDia = {
    name: "Fork Diameter",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_fLen = {
    name: "Fork Length",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  g_fR = {
    name: "Fork Rake",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  g_fAgl = {
    name: "Fork Angle",
    val: "",
    sVal: "none",
    attr: "num",
    desc: "disp",
  };
  g_fHrz = {
    name: "For kHorizontally",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  g_fVrt = {
    name: "Fork Vertically",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  gc_fTpr = {
    name: "Fork Tapered",
    val: "",
    sVal: "none",
    attr: "num",
    desc: "none",
  };
  g_so = {
    name: "StandOver",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  g_stk = {
    name: "Stack",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  g_rch = {
    name: "Reach",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  gc_ts = {
    name: "TireSize",
    val: "",
    sVal: "",
    attr: "num",
    desc: "disp",
  };
  g_rwEtoC = {
    name: "RearWheel End To Center",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  gc_rwOfs = {
    name: "RearWheel Offset",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  gc_re = {
    name: "RearEnd Length",
    val: "",
    sVal: "",
    attr: "num",
    desc: "none",
  };
  gc_cal = {
    name: "Calculation",
    val: "",
    sVal: "",
    attr: "str",
    desc: "none",
  };
  md_ntc = {
    name: "Notice",
    val: "",
    sVal: "",
    attr: "str",
    desc: "none",
  };
  md_src = {
    name: "Source",
    val: "",
    sVal: "",
    attr: "str",
    desc: "disp",
  };
}
